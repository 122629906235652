import API from '../api/api';
import baseDataReducer from './baseDataReducer';

const { iniState, reducer, AC, thunks, withLoadingStatusUpdate } = baseDataReducer('companyTypes');

const initialState = {
  ...iniState,
  offset: 0,
  filters: {},
  sorting: {},
};

const companyTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const onFilter = thunks.onFilter;
export const onSort = thunks.onSort;
export const setOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const clearItems = () => (dispatch) => dispatch(AC.clearItems());

export const clearCompaniesTypes = () => (dispatch) => {
  dispatch(AC.clearItemsAndTotal());
};

export const getCompaniesTypes = (params = { offset: 0 }) =>
  withLoadingStatusUpdate(async (dispatch) => {
    let data = await API.companies.getTypes(params);
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(data.total));
  });

export const saveType = (data) =>
  withLoadingStatusUpdate(async (dispatch) => {
    const res = await API.companies.saveType(data);
    dispatch(AC.clearItemsAndTotal());
    return res;
  });

export const removeType = (data) =>
  withLoadingStatusUpdate(async (dispatch) => {
    const res = await API.companies.removeType(data.id);
    dispatch(AC.clearItemsAndTotal());
    return res;
  });

export default companyTypesReducer;
