import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate } = baseDataReducer('giftCards');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

/* 
    On add, remove or clear filters we are setting offset to 0 and clearing items under the hood(see baseDataReducer.js) 
*/
export const onFilter = thunks.onFilter;
export const onSort = thunks.onSort;

const giftCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const setOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const clearGiftCards = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getGiftCards =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.giftCards.getCards({ offset, limit, ...params });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
    } catch (e) {
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error when loading gift cards', deb_info: e });
    }
  };

export const clearGiftCardsItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};

export default giftCardsReducer;
