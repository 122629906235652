import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';
import { logout } from './session-reducer';
const { AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate } = baseDataReducer('marketplaceOrders');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {
    types: [1, 2, 3, 4],
  },
  offset: 0,
  statuses: {
    1: '#EDAB00',
    2: '#EA7000',
    3: '#D176FB',
    4: '#04CAE5',
    5: '#0094FD',
    6: '#36A900',
    7: '#D10000',
  },
  item_statuses: {
    1: '#EDAB00',
    2: '#EA7000',
    3: '#D10000',
    4: '#04CAE5',
    5: '#D176FB',
    6: '#36A900',
  },
  payment_statuses: {
    'Not Paid': '#EDAB00',
    Paid: '#36A900',
    'Pay on Pickup': '#D10000',
    'Paid by gift card': '#efd32e',
  },
};

const SET_MARKETPLACE_ORDER_ITEMS_STATE = '/nibchain/marketplace-orders/SET_MARKETPLACE_ORDER_ITEMS_STATE';
const SET_MARKETPLACE_ORDER_ITEMS_CHECK_STATE = '/nibchain/marketplace-orders/SET_MARKETPLACE_ORDER_ITEMS_CHECK_STATE';
const SET_MARKETPLACE_ORDER_STATE = '/nibchain/marketplace-orders/SET_ORDER_STATE';
const SET_MARKETPLACE_ORDER_ITEM_QUANTITY = '/nibchain/marketplace-orders/SET_MARKETPLACE_ORDER_ITEM_QUANTITY';

const NOT_AVAILABLE_ITEM_STATE_ID = 3;
const REFUNDED_ITEM_STATE_ID = 7;
const NOT_PAID_ORDER_PAYMENT_STATE = 'Not Paid';

const ORDER_STATE_COMPLETED_STATE = 6;

/* 
    On add, remove or clear filters we are setting offset to 0 and clearing items under the hood(see baseDataReducer.js) 
*/
export const onOrdersFilter = thunks.onFilter;

const marketplaceOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MARKETPLACE_ORDER_ITEMS_STATE:
      for (const [k, v] of Object.entries(state.items)) {
        if (v.id === action.orderId) {
          for (const [ik, iv] of Object.entries(v.items)) {
            if (typeof action.data[iv.item_id] !== 'undefined') {
              state.items[k].items[ik].state = action.data[iv.item_id].state;
            }
          }
        }
      }
      return state;

    case SET_MARKETPLACE_ORDER_STATE:
      for (const [k, v] of Object.entries(state.items)) {
        state.items[k].changeStateButton = false;
        for (const [ik, iv] of Object.entries(v.items)) {
          state.items[k].items[ik].checkBoxState = false;
        }

        if (v.id === action.orderId) {
          if (typeof action.data[action.orderId] !== 'undefined') {
            state.items[k].state = action.data[action.orderId].state;
          }
        }
      }
      return state;

    case SET_MARKETPLACE_ORDER_ITEM_QUANTITY:
      for (const [k, v] of Object.entries(state.items)) {
        if (v.id === action.orderId) {
          state.items[k].sales_total = action.data[action.orderId].sales_total;
          state.items[k].vendor_income = action.data[action.orderId].vendor_income;
          state.items[k].totals = action.data[action.orderId].totals;
          state.items[k].totals_with_tax = action.data[action.orderId].totals_with_tax;
          for (const [ik, iv] of Object.entries(v.items)) {
            if (iv.item_id === action.itemId && typeof action.data[action.orderId].items[iv.item_id] !== 'undefined') {
              state.items[k].items[ik].quantity = action.data[action.orderId].items[action.itemId].quantity;
              state.items[k].items[ik].total = action.data[action.orderId].items[action.itemId].total;
              state.items[k].items[ik].rate = {
                procent: action.data[action.orderId].items[action.itemId].rate.procent,
                value: action.data[action.orderId].items[action.itemId].rate.value,
              };
            }
          }
        }
      }
      return state;

    case SET_MARKETPLACE_ORDER_ITEMS_CHECK_STATE:
      for (const [k, v] of Object.entries(state.items)) {
        if (v.id === action.orderId) {
          for (const [ik, iv] of Object.entries(v.items)) {
            if (iv.item_id === action.itemId) {
              state.items[k].items[ik].checkBoxState = action.state;
              if (
                action.state &&
                state.items[k].changeStateButton === false &&
                iv.state.id != REFUNDED_ITEM_STATE_ID &&
                iv.state.id != NOT_AVAILABLE_ITEM_STATE_ID &&
                v.payment_state != NOT_PAID_ORDER_PAYMENT_STATE &&
                v.state.id != ORDER_STATE_COMPLETED_STATE
              ) {
                state.items[k].changeStateButton = true;
              }
            }
          }
        }
      }

      return state;

    default:
      for (const [k, v] of Object.entries(state.items)) {
        state.items[k].changeStateButton = false;
        for (const [ik, iv] of Object.entries(v.items)) {
          state.items[k].items[ik].checkBoxState = false;
        }
      }
      return reducer(state, action);
  }
};

export const setOrdersItemsCheckBoxState = (orderId, itemId, state) => (dispatch) => {
  dispatch({
    type: SET_MARKETPLACE_ORDER_ITEMS_CHECK_STATE,
    orderId: orderId,
    itemId: itemId,
    state: state,
  });
};
export const orderClearItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};

export const setOrderOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const clearOrders = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getMarketplaceOrders =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.marketplace.getOrders({ offset, limit, ...params });

      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
    } catch (e) {
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error when loading marketplace orders', deb_info: e });
    }
  };

export const removeOrder = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.removeOrder({ id });
    dispatch(AC.toggleInit(false));
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on remove order!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const updateOrderItemQuantity = (orderId, itemId, quantity) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.updateOrderItemQuantity({ itemId, quantity });

    dispatch({
      type: SET_MARKETPLACE_ORDER_ITEM_QUANTITY,
      orderId: orderId,
      itemId: itemId,
      data: data.data,
    });

    dispatch(AC.toggleInit(false));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on update order item quantity order!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const updateOrderItemsState = (orderId, idList, state) => async (dispatch) => {
  let itemIds = [];
  for (const [key, value] of Object.entries(idList)) {
    var temp = key.toString().split('_');
    if (orderId == temp[0] && value) {
      itemIds.push(temp[1]);
    }
  }
  let data = {
    order: orderId,
    items: itemIds,
    state: state,
  };

  let dataToSend = helpers.formatData(data, {
    toMulti: ['items'],
  });

  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.updateOrderItemsState(dataToSend);

    dispatch({
      type: SET_MARKETPLACE_ORDER_ITEMS_STATE,
      orderId: orderId,
      data: data.data,
    });

    dispatch({
      type: SET_MARKETPLACE_ORDER_STATE,
      orderId: orderId,
      data: data.order,
    });

    dispatch(AC.toggleInit(false));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on update order items status!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const refundOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.refundOrder({ order: orderId });

    dispatch({
      type: SET_MARKETPLACE_ORDER_ITEMS_STATE,
      orderId: orderId,
      data: data,
    });

    dispatch(AC.toggleInit(false));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Order refund error!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const cancelOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.cancelOrder({ order: orderId });

    dispatch({
      type: SET_MARKETPLACE_ORDER_STATE,
      orderId: orderId,
      data: data.data,
    });

    dispatch(AC.toggleInit(false));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Order cancel error!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const completeOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.completeOrder({ order: orderId });

    dispatch({
      type: SET_MARKETPLACE_ORDER_STATE,
      orderId: orderId,
      data: data.data,
    });

    dispatch(AC.toggleInit(false));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Order complete error!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const getOrderStates = async (offset = 0, limit = 20) => {
  let data = await API.marketplace.getOrderStates({ offset, limit });
  return data.items || [];
};

export const editOrder = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    dispatch(AC.editItem(data));
    let dataToSend = helpers.formatData(data, {
      removeIfEmpty: ['password'],
    });
    let status = await API.marketplace.editOrder(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit order!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default marketplaceOrdersReducer;
