import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import API from '../api/api';
import { getBackendUrl } from '../general/helpers';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { iniState, reducer, ACTIONS, AC, thunks } = baseDataReducer('documents');

const SET_TYPES = '/nibchain/documents/SET_TYPES';
const SET_DOC_TO_EDITED = '/nibchain/documents/SET_DOC_TO_EDITED';
const SET_DOC_TO_ADDED = '/nibchain/documents/SET_DOC_TO_ADDED';
const SET_DOC_TO_REMOVED = '/nibchain/documents/SET_DOC_TO_REMOVED';
const CLEAR_CHANGES = '/nibchain/documents/CLEAR_CHANGES';
const UPDATE_ITEMS_ID_AND_LINK = '/nc/docs/UPDATE_ITEMS_ID_AND_LINK';

const initialState = {
  ...iniState,
  initial_items: [],
  items: [],
  total: 0,
  types: [],
  offset: 0,
  filters: {},
  sorting: {},
  editedDocs: [],
  addedDocs: [],
  removedDocs: [],
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_ITEMS:
      return {
        ...state,
        items: [
          ...state.items,
          ...action.payload.map((item) => ({ ...item, file_url: getBackendUrl(`/document/file/get?id=${item.id}`) })),
        ],
        initial_items: [
          ...state.items,
          ...action.payload.map((item) => ({ ...item, file_url: getBackendUrl(`/document/file/get?id=${item.id}`) })),
        ],
      };
    case SET_TYPES:
      return {
        ...state,
        types: [...action.payload],
      };
    case SET_DOC_TO_EDITED:
      return {
        ...state,
        editedDocs: [...state.editedDocs, action.payload],
      };
    case SET_DOC_TO_ADDED:
      return {
        ...state,
        addedDocs: [...state.addedDocs, action.payload],
        items: [...state.items, action.payload],
      };
    case SET_DOC_TO_REMOVED:
      return {
        ...state,
        removedDocs: [...state.removedDocs, action.payload],
      };
    case CLEAR_CHANGES:
      return {
        ...state,
        removedDocs: [],
        addedDocs: [],
        editedDocs: [],
      };
    case ACTIONS.CLEAR_ITEMS_AND_TOTAL:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_ITEMS_ID_AND_LINK:
      // Updates the ID on element after insert (upload) element
      return {
        ...state,
        items: [
          ...state.items.map((item) => {
            if (item.id === action.payload.fromDoc.id) {
              return {
                ...item,
                id: action.payload.toDoc.id,
                file_url: getBackendUrl(`/document/file/get?id=${action.payload.toDoc.id}`),
              };
            }
            return item;
          }),
        ],
        initial_items: [
          ...state.items.map((item) => {
            if (item.id === action.payload.fromDoc.id) {
              return {
                ...item,
                id: action.payload.toDoc.id,
                file_url: getBackendUrl(`/document/file/get?id=${action.payload.toDoc.id}`),
              };
            }
            return item;
          }),
        ],
      };
    default:
      return reducer(state, action);
  }
};

const setTypesAC = (types) => ({ type: SET_TYPES, payload: types });
export const setDocsToEditedAC = (doc) => ({ type: SET_DOC_TO_EDITED, payload: doc });
export const setDocsToAddedAC = (doc) => ({ type: SET_DOC_TO_ADDED, payload: doc });
export const setDocsToRemovedAC = (id) => ({ type: SET_DOC_TO_REMOVED, payload: id });
export const clearChangesAC = () => ({ type: CLEAR_CHANGES });

export const updateItemsIdAndLink = (doc) => ({ type: UPDATE_ITEMS_ID_AND_LINK, payload: doc });

export const setItemsAC = AC.setItems;
export const editItemAC = AC.editItem;
export const clearItemsAndTotal = AC.clearItemsAndTotal;
// export const removeItemAC = AC.removeItem;

export const removeItemAC = (id) => async (dispatch) => {
  dispatch(AC.toggleLoading(true));
  await API.documents.remove({ id });
  dispatch(AC.removeItem(id));
  dispatch(AC.toggleLoading(false));
};

export const getDocumentsTypesByEntity = (entity) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let types = await API.documents.getTypes({ entity });
    dispatch(setTypesAC(types.items));
    dispatch(AC.toggleLoading(false));
  } catch (error) {
    dispatch(AC.toggleLoading(false));
    console.log(error);
    onError({ status: 0, message: 'Error on get documents types!', deb_info: error });
    return false;
  }
};

export const saveDocuments =
  ({ entity, id, handover, coordinates = null, locationName = null }) =>
  async (dispatch, getState) => {
    try {
      dispatch(AC.toggleLoading(true));
      const { editedDocs, addedDocs, removedDocs } = getState().documents;

      if (editedDocs && editedDocs.length) {
        for (const doc of editedDocs) {
          let types = doc.types.filter((type) => type.active).map((type) => type.id);
          await API.documents.edit({ ...doc, 'types[]': types, [entity]: id });
        }
      }
      if (addedDocs && addedDocs.length) {
        for (const doc of addedDocs) {
          try {
            let types = doc.types.filter((type) => type.active).map((type) => type.id);
            const data = { ...doc, 'types[]': types, [entity]: id, coordinates, locationName };
            if (handover) data.handover = handover;
            let retData = await API.documents.add(data);
            dispatch(updateItemsIdAndLink({ fromDoc: data, toDoc: retData }));
          } catch (err) {
            console.log(err);
          }
        }
      }
      if (removedDocs && removedDocs.length) {
        for (const doc of removedDocs) {
          await API.documents.remove({ id: doc });
        }
      }
      dispatch(clearChangesAC());
      dispatch(AC.toggleLoading(false));
      return true;
    } catch (error) {
      console.log(error);
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error on save documents!', deb_info: error });
      return false;
    }
  };

export const getDocuments =
  ({
    limit = TABLE_PAGE_SIZE,
    offset = 0,
    search,
    company,
    active,
    entity,
    startDate,
    endDate,
    orderCreatedAtDate,
    orderEntity,
    orderCompany,
    orderTypes,
  }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      let documents = await API.documents.get({
        limit,
        offset,
        search,
        company,
        active,
        entity,
        startDate,
        endDate,
        orderCreatedAtDate,
        orderEntity,
        orderCompany,
        orderTypes,
      });
      dispatch(AC.setItems(documents.items));
      dispatch(AC.setTotal(documents.total));
      dispatch(AC.toggleLoading(false));
      return documents.items;
    } catch (error) {
      onError({ status: 0, message: 'Error on get documents!', deb_info: error });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export const onFilter = thunks.onFilter;

export const onSort = thunks.onSort;

export const setOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const clearItems = () => (dispatch) => dispatch(AC.clearItems());

export default documentsReducer;
