import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, thunks } = baseDataReducer('marketplaceIcons');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

const marketplaceIconsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const onIconsFilter = thunks.onFilter;
export const onIconsSort = thunks.onSort;

export const clearIconsItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};

export const setIconsOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const clearIcons = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const getMarketplaceIcons =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.marketplace.getIcons({ offset, limit, ...params });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
    } catch (e) {
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error when loading marketplace icons', deb_info: e });
    }
  };

export const removeIcon = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    await API.marketplace.removeIcon({ id });
    dispatch(AC.toggleInit(false));
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on remove icon!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const editIcon = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    dispatch(AC.editItem(data));
    let dataToSend = helpers.formatData(data, {
      remove: ['asset', 'created_at'],
    });
    let status = await API.marketplace.editIcon(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit icon!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const uploadIcons = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      toMulti: ['icons', 'categories'],
    });
    let status = await API.marketplace.uploadIcons(dataToSend);
    for (const icon of status) {
      dispatch(AC.addItem(icon));
    }
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on upload icons!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default marketplaceIconsReducer;
