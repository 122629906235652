import baseDataReducer from '../baseDataReducer';
import API from '../../api/api';
import { addError, certificateActions, clearErrors } from './certificates-actions';
import { cloneDeep } from 'lodash';
import { format, parse, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { CERTIFICATE_STATES } from './certificates-data';

const { iniState, reducer, AC } = baseDataReducer('certificates');

const initialState = {
  ...iniState,
  filters: {},
  sorting: {},
  localCertificates: [],
  editedCertificates: [],
  newCertificates: [],
  states: CERTIFICATE_STATES,
  currentEditingComment: null,
  errors: null,
};

const certificatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case certificateActions.addFilter: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.type]: action.payload.value,
        },
      };
    }
    case certificateActions.addVariantStates: {
      return {
        ...state,
        states: action.payload,
      };
    }
    case certificateActions.setNewCertificates: {
      return {
        ...state,
        localCertificates: [...action.payload, ...state.localCertificates],
        newCertificates: [...state.newCertificates, ...action.payload],
      };
    }
    case certificateActions.setLocalCertificates: {
      return {
        ...state,
        localCertificates: action.payload,
      };
    }
    case certificateActions.sortCertificates: {
      const { sortBy, order } = action.payload;
      const sortedItems = [...state.localCertificates].sort((a, b) =>
        order === 'ASC' ? (a[sortBy] > b[sortBy] ? 1 : -1) : a[sortBy] < b[sortBy] ? 1 : -1,
      );
      return {
        ...state,
        localCertificates: sortedItems,
        sorting: { [sortBy]: order },
      };
    }
    case certificateActions.updateCertificateField: {
      const { id, key, value } = action.payload;
      const updatedCertificates = cloneDeep(state.localCertificates);
      const certificate = updatedCertificates.find((cert) => cert.id === id);
      certificate[key] = value;

      const editedCertificates = cloneDeep(state.editedCertificates);
      const newCertificates = cloneDeep(state.newCertificates);
      const originalCertificate = state.items.find((cert) => cert.id === id);
      if (originalCertificate?.[key] == value) {
        return {
          ...state,
          localCertificates: updatedCertificates,
          newCertificates: certificate?.isNew ? newCertificates.filter((cert) => cert.id !== id) : newCertificates,
          editedCertificates: !certificate?.isNew
            ? editedCertificates.filter((cert) => cert.id !== id)
            : editedCertificates,
        };
      } else {
        return {
          ...state,
          localCertificates: updatedCertificates,
          newCertificates: certificate?.isNew
            ? [...newCertificates.filter((cert) => cert.id !== id), certificate]
            : newCertificates,
          editedCertificates: !certificate?.isNew
            ? [...editedCertificates.filter((cert) => cert.id !== id), certificate]
            : editedCertificates,
        };
      }
    }
    case certificateActions.updateMultipleCertificateField: {
      const { ids, key, value } = action.payload;
      const updatedCertificates = cloneDeep(state.localCertificates);

      updatedCertificates.forEach((certificate) => {
        if (ids.includes(certificate.id)) {
          certificate[key] = value;
        }
      });

      const newCertificates = [];
      const editedCertificates = [];

      updatedCertificates.forEach((certificate) => {
        const originalCertificate = state.items.find((cert) => cert.id === certificate.id);

        if (!originalCertificate || originalCertificate[key] !== certificate[key]) {
          if (certificate.isNew) {
            newCertificates.push(certificate);
          } else {
            editedCertificates.push(certificate);
          }
        }
      });

      return {
        ...state,
        localCertificates: updatedCertificates,
        newCertificates,
        editedCertificates,
      };
    }
    case certificateActions.toggleCertificateStatus: {
      const { id, stateName, states } = action.payload;
      const updatedCertificates = cloneDeep(state.localCertificates);
      const certificate = updatedCertificates.find((cert) => cert.id === id);

      if (certificate.stateName === 'sold') {
        return { ...state };
      }
      if (stateName !== 'sold') {
        const toggledState = states.find((state) => state.name !== stateName && stateName !== 'sold');
        if (toggledState) {
          certificate.state = `${toggledState.id}`;
          certificate.stateName = toggledState.name;
        }
      }

      const originalCertificate = state.items.find((cert) => cert.id === id);
      if (originalCertificate?.state === certificate.state) {
        return {
          ...state,
          localCertificates: updatedCertificates,
          newCertificates: certificate?.isNew
            ? state.newCertificates.filter((cert) => cert.id !== id)
            : state.newCertificates,
          editedCertificates: !certificate?.isNew
            ? state.editedCertificates.filter((cert) => cert.id !== id)
            : state.editedCertificates,
        };
      } else {
        return {
          ...state,
          localCertificates: updatedCertificates,
          newCertificates: certificate?.isNew
            ? [...state.newCertificates.filter((cert) => cert.id !== id), certificate]
            : state.newCertificates,
          editedCertificates: !certificate?.isNew
            ? [...state.editedCertificates.filter((cert) => cert.id !== id), certificate]
            : state.editedCertificates,
        };
      }
    }
    case certificateActions.toggleMultipleCertificateStatus: {
      const { ids, stateName, states } = action.payload;
      const updatedCertificates = cloneDeep(state.localCertificates);

      ids.forEach((id) => {
        const certificate = updatedCertificates.find((cert) => cert.id === id);

        if (certificate?.stateName === 'sold') return;

        if (stateName !== 'sold') {
          const toggledState = states.find((state) => state?.name !== stateName && stateName !== 'sold');
          if (toggledState) {
            certificate.state = `${toggledState.id}`;
            certificate.stateName = toggledState.name;
          }
        }
      });

      const originalCertificates = state.items;

      const newCertificates = [];
      const editedCertificates = [];

      updatedCertificates.forEach((certificate) => {
        const originalCertificate = originalCertificates.find((cert) => cert.id === certificate.id);

        if (!originalCertificate || originalCertificate.state !== certificate.state) {
          if (certificate.isNew) {
            newCertificates.push(certificate);
          } else {
            editedCertificates.push(certificate);
          }
        }
      });

      return {
        ...state,
        localCertificates: updatedCertificates,
        newCertificates,
        editedCertificates,
      };
    }
    case certificateActions.setCurrentEditingComment: {
      return {
        ...state,
        currentEditingComment: action.payload,
      };
    }
    case certificateActions.removeNewCertificate: {
      const removingCertificate = state.localCertificates.find((c) => c.id === action.payload.id);
      if (removingCertificate?.isNew) {
        return {
          ...state,
          localCertificates: state.localCertificates.filter((c) => c.id !== action.payload.id),
          newCertificates: state.newCertificates.filter((c) => c.id !== action.payload.id),
        };
      } else {
        return { ...state };
      }
    }
    case certificateActions.addError: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.id]: {
            ...state.errors?.[action.payload.id],
            [action.payload.field]: action.payload.message,
          },
        },
      };
    }
    case certificateActions.filterByDate: {
      const { startDate, endDate } = action.payload;
      const parsedStartDate = parse(startDate, 'dd.MM.yyyy', new Date());
      const parsedEndDate = parse(endDate, 'dd.MM.yyyy', new Date());

      const filteredCertificates = state.localCertificates.filter((certificate) => {
        const certDate = parse(certificate.created_at, 'dd.MM.yyyy', new Date());
        return certDate >= parsedStartDate && certDate <= parsedEndDate;
      });
      return {
        ...state,
        localCertificates: filteredCertificates,
      };
    }
    case certificateActions.filterBySearch: {
      const { searchTerm } = action.payload;

      const filteredCertificates = state.localCertificates.filter((certificate) => {
        return (
          certificate?.buyer?.company_name?.toLowerCase().includes(searchTerm) ||
          certificate?.buyer?.txn_id?.toLowerCase().includes(searchTerm) ||
          certificate?.buyer?.date?.date?.toLowerCase().includes(searchTerm) ||
          certificate?.comment?.toLowerCase().includes(searchTerm) ||
          certificate?.created_at?.toLowerCase().includes(searchTerm) ||
          certificate?.rdid?.toLowerCase().includes(searchTerm) ||
          certificate?.price?.toString().includes(searchTerm) ||
          certificate?.stateName?.toString().includes(searchTerm)
        );
      });

      return {
        ...state,
        localCertificates: filteredCertificates,
      };
    }
    case certificateActions.filterByStatus: {
      const { status } = action.payload;

      const filteredCertificates = state.localCertificates.filter((certificate) => {
        return certificate?.state?.toString()?.includes(status?.toString());
      });

      return {
        ...state,
        localCertificates: filteredCertificates,
      };
    }
    case certificateActions.resetFilters: {
      return { ...state, filters: {} };
    }
    case certificateActions.resetCertificates: {
      return { ...state, items: [] };
    }
    case certificateActions.resetLocalCertificates: {
      return { ...state, localCertificates: cloneDeep(state.items) };
    }
    case certificateActions.resetNewCertificates: {
      return { ...state, newCertificates: [] };
    }
    case certificateActions.resetEditedCertificates: {
      return { ...state, editedCertificates: [] };
    }
    case certificateActions.resetSorting: {
      return { ...state, sorting: {} };
    }
    case certificateActions.resetCurrentEditingComment: {
      return { ...state, currentEditingComment: null };
    }
    case certificateActions.clearErrors: {
      return { ...state, errors: {} };
    }
    default:
      return reducer(state, action);
  }
};

export default certificatesReducer;

export const getVariantStateNameById = (id, states) => {
  const foundState = states.find((st) => st.id === id);
  return foundState ? foundState.name : '';
};

export const getCertificates = (orderId) => async (dispatch, getState) => {
  dispatch(AC.toggleLoading(true));
  const data = await API.orders.getCertificates(orderId);
  const states = getState().certificates.states;
  if (data?.length) {
    dispatch(
      AC.setItems(
        data.map((item) => {
          const date = parseISO(item.created_at);
          const formattedDate = format(date, 'dd.MM.yyyy');
          return {
            ...item,
            created_at: formattedDate,
            stateName: getVariantStateNameById(Number(item?.state) || null, states),
          };
        }),
      ),
    );
    dispatch(AC.setTotal(data.length));
  }
  dispatch(AC.toggleLoading(false));
  dispatch(AC.toggleInit(true));
};

export const saveCertificates = (orderId) => async (dispatch, getState) => {
  dispatch(clearErrors());
  const editedCertificates = getState().certificates.editedCertificates;
  const newCertificates = getState().certificates.newCertificates;
  let isErrors = false;

  for (const certificate of editedCertificates) {
    if ((!certificate?.price || +certificate.price <= 0) && certificate.stateName === 'exchange') {
      dispatch(addError(certificate.id, 'price', 'Cannot set certificates with a price of 0 to EXCHANGE.'));
      isErrors = true;
    }
  }

  for (const certificate of newCertificates) {
    if ((!certificate?.price || +certificate.price <= 0) && certificate.stateName === 'exchange') {
      dispatch(addError(certificate.id, 'price', 'Cannot set certificates with a price of 0 to EXCHANGE.'));
      isErrors = true;
    }
  }

  if (isErrors) {
    return null;
  }

  const formattedEditedCertificates = editedCertificates.map((c) => {
    return {
      id: +c.id,
      comment: c.comment,
      state: +c.state,
      price: +c.price,
    };
  });

  const formattedNewCertificates = newCertificates.map((c) => {
    return {
      comment: c.comment,
      state: +c.state,
      price: +c.price,
    };
  });
  try {
    dispatch(AC.toggleLoading(true));
    const res = await API.orders.saveCertificates(
      orderId,
      JSON.stringify([...formattedEditedCertificates, ...formattedNewCertificates]),
    );
    if (res?.length >= 0) {
      toast.success('Certificates were successfully saved!');
    }
    return res;
  } catch {
    toast.error('Error on saving certificates!');
  } finally {
    dispatch(AC.toggleLoading(false));
  }
};
