export const CERTIFICATE_STATES = [
  {
    id: 1,
    name: 'exchange',
  },
  {
    id: 2,
    name: 'storage',
  },
  {
    id: 3,
    name: 'sold',
  },
];
