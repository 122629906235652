export const necsActions = {
  setNecsAccount: '/nibchain/necs/SET_NECS_ACCOUNT',
  setGoAccounts: '/nibchain/necs/SET_GO_ACCOUNTS',
  setGoCertificatesOnExchange: '/nibchain/necs/SET_GO_CERTIFICATES_ON_EXCHANGE',
  setTotalGoCertificates: '/nibchain/necs/SET_TOTAL_GO_CERTIFICATES',
};

export const setNecsAccount =
  ({ account_holder_code, email, id, organization_name, status }) =>
  (dispatch) => {
    dispatch({
      type: necsActions.setNecsAccount,
      payload: { account_holder_code, email, id, organization_name, status },
    });
  };

export const removeNecsAccount = () => (dispatch) => {
  dispatch({
    type: necsActions.setNecsAccount,
    payload: null,
  });
};

export const setGoAccounts = (accounts) => (dispatch) => {
  dispatch({
    type: necsActions.setGoAccounts,
    payload: accounts,
  });
};

export const setGoCertificatesOnExchange = (goCertificatesOnExchange) => (dispatch) => {
  dispatch({
    type: necsActions.setGoCertificatesOnExchange,
    payload: goCertificatesOnExchange,
  });
};

export const setTotalGoCertificates = (totalGoCertificates) => (dispatch) => {
  dispatch({
    type: necsActions.setTotalGoCertificates,
    payload: totalGoCertificates,
  });
};
