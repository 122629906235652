import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, ACTIONS, thunks } = baseDataReducer('marketplaceOrdersOld');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  offset: 0,
  filters: {},
};

const marketplaceOrdersOldReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const setOrderOffset = (offset) => (dispatch) => {
  console.log('setOrderOffset', offset);
  dispatch(AC.setOffset(offset));
};

export const onFilter = thunks.onFilter;
// export const setProductsOffset = (offset) => (dispatch) => dispatch(AC.setOffset(offset));

export const getOrderStates = async (offset = 0, limit = 20) => {
  let data = await API.marketplace.getOrderStates({ offset, limit });
  return data.items || [];
};

export const toggleLoading = (status) => (dispatch) => {
  dispatch(AC.toggleLoading(status));
};

// export const getProductsByNamePattern = (namePattern) => async (dispatch) => {
//     let data = await API.products.get({name: namePattern})
//     return data.items;
// }

// export const getProductsByNameAndCompany = (company_id) => async (namePattern) => {
//     if(!company_id || !namePattern){
//         return []
//     }
//     let data = await API.products.getByCompanyAndID({name: namePattern, company_id})
//     return data.items;
// }

// export const getProducts = ({offset = 0, limit = TABLE_PAGE_SIZE, endDate, startDate, company_id, search, active}) => async (dispatch) => {
//     dispatch(AC.toggleLoading(true));
//     let data = await API.marketplace.getOrders({offset, limit, endDate, startDate, company_id, search, active});
//     dispatch(AC.setItems(data.items));
//     dispatch(AC.setTotal(parseInt(data.total)));
//     dispatch(AC.toggleLoading(false));
//     dispatch(AC.toggleInit(true));
//     return data;
// }

export const getOrders =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, search, ...rest }) =>
  async (dispatch, getState) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.marketplace.getOrders({ offset, limit, search, ...rest });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
      return data;
    } catch (err) {
      console.log(err);
      onError({ status: 0, message: 'Error on Order fetch', dev_info: err });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export const removeOrder = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.marketplace.removeOrder({ id });
    dispatch(AC.toggleInit(false));
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on remove Order!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const saveOrder = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const category = await API.marketplace.saveOrder(data);
    dispatch(AC.toggleLoading(false));
    return category;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on saveOrder', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

// export const editProduct = (data) => async (dispatch) => {
//     try {
//         dispatch(AC.toggleLoading(true));
//         dispatch(AC.editItem(data));
//         let dataToSend = helpers.formatData(data, {
//             getID: ["company"],
//             getBackEndDate: ["createdAt"],
//             getBackEndDateTime: ["start_date", "end_date"]
//         });
//         if(dataToSend.sub_suppliers){
//             dataToSend.sub_suppliers = JSON.stringify(data.sub_suppliers.map(company => ({id: company.id, submit_to_api: company.submit_to_api})));
//         }
//         let status = await API.products.edit(dataToSend);
//         dispatch(AC.toggleLoading(false));
//         return status;
//     } catch (error) {
//         console.log(error);
//         onError({status: 0, message: "Error on edit product!", deb_info: error})
//         dispatch(AC.toggleLoading(false));
//         return false;
//     }
// }

// export const addProduct = (data) => async (dispatch) => {
//     try {
//         dispatch(AC.toggleLoading(true));
//         let dataToSend = helpers.formatData(data, {
//             getID: ["company"],
//             getBackEndDateTime: ["start_date", "end_date"]
//         });
//         if(dataToSend.sub_suppliers && dataToSend.sub_suppliers.length !== 0){
//             dataToSend.sub_suppliers = JSON.stringify(dataToSend.sub_suppliers.map(company => ({id: company.id, submit_to_api: company.submit_to_api})));
//         }
//         let status = await API.products.add(dataToSend);
//         dispatch(AC.toggleLoading(false));
//         return status;
//     } catch (error) {
//         console.log(error);
//         onError({status: 0, message: "Error on add product!", deb_info: error});
//         dispatch(AC.toggleLoading(false));
//         return false;
//     }
// }

export const clearOrders = () => (dispatch) => {
  console.log('clearOrders');
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

// export const getOneProduct = (id) => async (dispatch) => {
//     dispatch(AC.toggleLoading(true));
//     let product = await API.products.getOneById({id});
//     dispatch(AC.toggleLoading(false));
//     return product;
// }

export default marketplaceOrdersOldReducer;
