import API from '../api/api';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';

const { iniState, reducer, AC } = baseDataReducer('companyRelations');

const initialState = {
  ...iniState,
};

export const addRelation = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const relation = await API.companies.addRelation(data);
    dispatch(AC.toggleLoading(false));
    return relation;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on relation add', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const removeRelation = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const responce = await API.companies.removeRelation(data);
    dispatch(AC.toggleLoading(false));
    return responce;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error when deleting a relationship', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const acceptRelation = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const status = await API.companies.acceptRelation(data);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on relation accept', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const declineRelation = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const status = await API.companies.declineRelation(data);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on relation accept', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default function companyRelationsReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
}
