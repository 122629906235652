import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, thunks } = baseDataReducer('reviewsProduct');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

const productReviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
};

export const onReviewsSort = thunks.onSort;
export const onReviewsFilter = thunks.onFilter;

export const setReviewsOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const getReviews =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.review.findProductReviews({ offset, limit, ...params });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
    } catch (e) {
      dispatch(AC.toggleLoading(false));
      onError({ status: 0, message: 'Error when loading product reviews', deb_info: e });
    }
  };

export const updateVisibility = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    dispatch(AC.editItem(data));
    let status = await API.review.updateProductReviewVisibility(data);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit review!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const clearReviews = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const clearReviewsItems = () => (dispatch) => dispatch(AC.clearItems());

export default productReviewsReducer;
