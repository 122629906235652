import API from '../api/api';
import { onError } from './error-reducer';
import baseDataReducer from './baseDataReducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, ACTIONS, thunks, withLoadingStatusUpdate } = baseDataReducer('users');

const SET_AVAILABLE_ROLES = 'nibchain/users/SET_AVAILABLE_ROLES';
AC.setAvailableRoles = (payload) => ({ type: SET_AVAILABLE_ROLES, payload });

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AVAILABLE_ROLES:
      return { ...state, availableRoles: action.payload };
    default:
      return reducer(state, action);
  }
};

/* 
    On add, remove or clear filters we are setting offset to 0 and clearing items under the hood(see baseDataReducer.js) 
*/
export const onUsersFilter = thunks.onFilter;
export const onUsersSort = thunks.onSort;
export const clearUserItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};
export const setUserOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const toggleLoading = (status) => (dispatch) => {
  dispatch(AC.toggleLoading(status));
};

export const getUsers =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...params }) =>
  async (dispatch, getState) => {
    dispatch(AC.toggleLoading(true));
    let data = await API.user.get({ offset, limit, ...params });
    dispatch(AC.setItems(data.items));
    dispatch(AC.setTotal(parseInt(data.total)));
    dispatch(AC.toggleLoading(false));
    dispatch(AC.toggleInit(true));
    return data;
  };

export const removeUser = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let data = await API.user.remove({ id });
    dispatch(AC.toggleInit(false));
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return true;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on remove user!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const editUser = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    dispatch(AC.editItem(data));
    let dataToSend = helpers.formatData(data, {
      getID: ['company'],
      removeIfEmpty: ['password'],
    });
    let status = await API.user.edit(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit user!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const editUserProfile = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let status = await API.user.editProfile(data);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on edit user profile!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const addUser = (data) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    let dataToSend = helpers.formatData(data, {
      getID: ['company'],
    });
    let status = await API.user.add(dataToSend);
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (error) {
    console.log(error);
    onError({ status: 0, message: 'Error on add user!', deb_info: error });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const clearUsers = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
  dispatch(AC.setAvailableRoles([]));
};

export const getOneUser = (id) => async (dispatch) => {
  dispatch(AC.toggleLoading(true));
  let user = await API.user.getOneById({ id });
  dispatch(AC.toggleLoading(false));
  return user;
};

export const getAvailableRoles = () =>
  withLoadingStatusUpdate(async (dispatch) => {
    const data = await API.user.getAvailableRoles();
    let roles = [];
    if (data.roles) {
      dispatch(AC.setAvailableRoles(data.roles));
      roles = data.roles;
    }
    return roles;
  });

export const updateUserRoles = async (userId, roles) => {
  const res = await API.user.updateRoles(userId, roles);
  return res;
};

export const bindCompaniesToUser = (userId, companies) =>
  withLoadingStatusUpdate(async (dispatch) => {
    const res = await API.user.bindCompanies(
      userId,
      companies.map((c) => c.id),
    );
    if (res) dispatch(AC.editItem({ id: userId, admin_companies_list: companies }));

    return res;
  });

export const getFlowAndMarketUsersByNamePattern = (params) => async (dispatch) => {
  let data = await API.user.findFlowAndMarket(params);
  return data.items;
};

export default usersReducer;
