import data from './countryData';

let countryList = data;
if (process.env.REACT_APP_COUNTRY_SELECTOR_BLACKLIST) {
  let payload = [];
  try {
    const blackList = JSON.parse(process.env.REACT_APP_COUNTRY_SELECTOR_BLACKLIST);
    payload = countryList.filter((country) => {
      if (!blackList.includes(country.countryName)) {
        return country;
      }
    });
    countryList = payload;
  } catch (error) {
    console.log(error);
  }
}

export const getAllCountries = () => {
  let payload = [];
  for (const country of countryList) {
    payload.push({
      label: country.countryName,
      code: country.countryShortCode,
    });
  }
  return payload;
};

export const getCountryByCode = (code) => {
  let payload = {};
  for (const country of countryList) {
    if (country.countryShortCode === code) {
      payload = {
        label: country.countryName,
        code: country.countryShortCode,
      };
    }
  }
  return payload;
};

export const getRegionsByCountryCode = (code) => {
  let payload = [];
  for (const country of countryList) {
    if (country.countryShortCode === code) {
      payload = [...country.regions];
    }
  }
  return payload;
};

export const getRegionByCode = (code) => {
  let allRegions = [];
  for (const country of countryList) {
    allRegions = [...allRegions, ...country.regions];
  }
  let payload = allRegions.filter((region) => region.code === code)[0];

  return payload;
};
