import API from '../api/api';
import baseDataReducer from './baseDataReducer';
import { onError } from './error-reducer';
import { TABLE_PAGE_SIZE } from 'general/constants';

const { AC, reducer, helpers, thunks } = baseDataReducer('marketplaceInventories');

const initialState = {
  isInited: false,
  isLoading: false,
  items: [],
  total: 0,
  filters: {},
  sorting: {},
  offset: 0,
};

export const onInventoryFilter = thunks.onFilter;
export const onInventorySort = thunks.onSort;

export const clearInventoryItems = () => (dispatch) => {
  dispatch(AC.clearItems());
};
export const removeInventory = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const status = await API.marketplace.removeInventory(id);
    dispatch(AC.removeItem(id));
    dispatch(AC.toggleLoading(false));
    return status;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on remove inventory', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export const setInventoryOffset = (offset) => (dispatch) => {
  dispatch(AC.setOffset(offset));
};

export const clearInventories = () => (dispatch) => {
  dispatch(AC.setOffset(0));
  dispatch(AC.clearItemsAndTotal());
};

export const saveInventory =
  (data, id = null) =>
  async (dispatch) => {
    try {
      dispatch(AC.toggleLoading(true));
      const payload = helpers.formatData(data, {
        getID: ['company', 'category', 'product'],
        toMulti: ['icons'],
      });
      const inventory = await API.marketplace.saveInventory(payload, id);
      dispatch(AC.editItem(inventory));
      dispatch(AC.toggleLoading(false));
      return inventory;
    } catch (err) {
      console.log(err);
      onError({ status: 0, message: 'Error on add inventory', dev_info: err });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export const updateInventoryStore = (inventory) => async (dispatch) => {
  dispatch(AC.editItem(inventory));
  return inventory;
};

export const getInventories =
  ({ offset = 0, limit = TABLE_PAGE_SIZE, ...rest }) =>
  async (dispatch, getState) => {
    try {
      dispatch(AC.toggleLoading(true));
      const data = await API.marketplace.getInventories({ offset, limit, ...rest });
      dispatch(AC.setItems(data.items));
      dispatch(AC.setTotal(parseInt(data.total)));
      dispatch(AC.toggleLoading(false));
      dispatch(AC.toggleInit(true));
      return data;
    } catch (err) {
      console.log(err);
      onError({ status: 0, message: 'Error on inventories fetch', dev_info: err });
      dispatch(AC.toggleLoading(false));
      return false;
    }
  };

export const getInventory = (id) => async (dispatch) => {
  try {
    dispatch(AC.toggleLoading(true));
    const data = await API.marketplace.getInventory(id);
    dispatch(AC.toggleLoading(false));
    return data;
  } catch (err) {
    console.log(err);
    onError({ status: 0, message: 'Error on inventory fetch', dev_info: err });
    dispatch(AC.toggleLoading(false));
    return false;
  }
};

export default function marketplaceInventoriesReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return reducer(state, action);
  }
}
